import {
  EditBotFormObjectModel,
  EditLogicalBot,
  UpdateLogicalBotRequestBody,
} from '@/types/edit-bot.types';

export function convertBotFormToRequestData(
  data: EditBotFormObjectModel,
  initialEditBotData: EditLogicalBot | null,
): UpdateLogicalBotRequestBody {
  const body: UpdateLogicalBotRequestBody = {
    logicalBot: {
      assignmentGroup: data.assignmentGroup.field.value,
      botAdditionalInfo: data.botAdditionalInfo.field.value || null,
      botDesignFolder: data.botDesignFolder.field.value,
      botDisabled: data.botDisabled.field.value,
      botName: data.botName.field.value,
      botRunRuntimeHours: initialEditBotData?.botRunRuntimeHours || null,
      botTriggerType: data.botTriggerType.field.value || null,
      chSplit: initialEditBotData?.chSplit || null,
      displayOrder: data.displayOrder.field.value || null,
      evidenceTemplate: data.evidenceTemplate.field.value || null,
      gracePeriodHours: initialEditBotData?.gracePeriodHours || null,
      idx: initialEditBotData ? initialEditBotData.idx : 0,
      logicalDeletion: !!initialEditBotData?.logicalDeletion,
      processId: initialEditBotData ? initialEditBotData.processId : '',
      requireSignOffs: !!initialEditBotData?.requireSignOffs,
      schedEndTime: data.schedEndTime.field.value || null,
      schedStartTime: data.schedStartTime.field.value || null,
      schedTimeZone: data.schedTimeZone.field.value || null,
      scheduleDescription: data.scheduleDescription.field.value || null,
      scheduleGroupId: data.scheduleGroupId.field.value !== '' ? Number(data.scheduleGroupId.field.value) : null,
      soxApprovalHumanApprover: initialEditBotData ? initialEditBotData.soxApprovalHumanApprover : '',
      soxApprovalHumanApproverName: initialEditBotData ? initialEditBotData.soxApprovalHumanApproverName : '',
      soxApprovalLastDate: initialEditBotData ? initialEditBotData.soxApprovalLastDate : '',
      soxDescription: data.soxDescription.field.value,
      soxDocumentReference: data.soxDocumentReference.field.value || null,
      soxHumanApprover: data.soxHumanApprover.field.value?.[0]?.mudId || null,
      soxReason: data.soxReason.field.value === 'null' ? null : data.soxReason.field.value,
      soxValue: data.soxValue.field.value,
      supportStaffWorkScheduleId: initialEditBotData ? initialEditBotData.supportStaffWorkScheduleId : '',
      supportTeam: data.supportTeam.field.value || null,
      updateNotes: data.updateNotes.field.value,
      lenientAutoSignoffFlag: false,
      ciId: data.ciId.field.value || null,
    },
  };

  const simpleSignOffBotId = data.simpleAutoSignoffBotId?.field?.value !== 'null'
    ? Number(data.simpleAutoSignoffBotId?.field.value)
    : null;
  body.logicalBot.simpleAutoSignoffBotId = simpleSignOffBotId;

  const lenientSignOffFlag = !simpleSignOffBotId && !!data.lenientAutoSignoffFlag?.field?.value;
  body.logicalBot.lenientAutoSignoffFlag = lenientSignOffFlag;
  body.logicalBot.lenientApprover = lenientSignOffFlag
    ? data.lenientApprover?.field?.value?.trim()
    : null;

  return body;
}
